import React from 'react';
import { ControlledMenu, MenuItem, useClick } from '@szhsin/react-menu';
import { useState } from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import TriangularArrowDown from '../../../assets/images/icons/other/triangular-arrow-down.inline.svg';

function Dropdown({ dropdownOptions, dropdownValue, setDropdownValue, dropdownError, setDropdownError, withoutIcon, dropdownIndex, valueField }) {
  const dropdownButtonRef = useRef(null);
  const dropdownInputRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpenWithDelay, setDropdownOpenWithDelay] = useState(false);
  const [dropdownFilter, setDropDownFilter] = useState('');
  const anchorProps = useClick(isDropdownOpen, setDropdownOpen);

  const generateDropdownOptions = useCallback(() => {
    if (!dropdownOptions) {
      return;
    }

    const components = [];

    dropdownOptions.forEach((item, index) => {
      if (!item[valueField]?.toLowerCase()?.includes(dropdownFilter.toLowerCase())) return;
      components.push(
        <MenuItem
          key={index}
          onClick={() => {
            setDropdownError(prevState => prevState.filter(errorIndex => errorIndex !== dropdownIndex));
            setDropdownValue(item, dropdownIndex, valueField);
          }}
        >
          {item[valueField]}
        </MenuItem>
      );
    });

    return components;
  }, [dropdownOptions, dropdownFilter, setDropdownValue, dropdownIndex, dropdownError]);

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target !== dropdownInputRef.current && isDropdownOpenWithDelay) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isDropdownOpenWithDelay]);

  useEffect(() => {
    setTimeout(() => {
      setDropdownOpenWithDelay(isDropdownOpen);
    }, 200);
  }, [isDropdownOpen]);

  return (
    <>
      <div
        ref={dropdownButtonRef}
        {...anchorProps}
        className={isDropdownOpen ? 'dropdown-button dropdown-button--open' : 'dropdown-button'}
        style={{ borderColor: (dropdownIndex !== false ? (dropdownError.includes(dropdownIndex)) : dropdownError) ? '#da2972' : null }}
      >
        {isDropdownOpen ? (
          <>
            <input
              ref={dropdownInputRef}
              type="text"
              placeholder="Type to filter"
              value={dropdownFilter}
              onChange={(e) => setDropDownFilter(e.target.value)}
              autoFocus
            />
            {!withoutIcon && (
                <TriangularArrowDown />
            )}
          </>
        ) : (
          <>
            <p>{dropdownIndex !== false ? dropdownValue[dropdownIndex]?.value : dropdownValue.value}</p>
            {!withoutIcon && (
                <TriangularArrowDown />
            )}
          </>
        )}
      </div>
      <ControlledMenu
        transition
        direction="bottom"
        viewScroll="initial"
        position="initial"
        state={isDropdownOpen}
        anchorRef={dropdownButtonRef}
        onClose={() => {
          setDropdownOpen(false);
        }}
      >
        <>{generateDropdownOptions()}</>
      </ControlledMenu>
    </>
  );
}

export default Dropdown;
